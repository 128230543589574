<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Authentication Manager
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">The Authentication manager provides the functionality to modify the Core Container API access.</p>
          <p>Both administrative user credentials and API keys used by data apps can be created, modified, and deleted via this page.</p>
        </em>
      </card-component>
      <card-component
        title="Administrative users"
        class="has-table has-mobile-sort-spaced"
        icon="file-check-outline"
        headerColor="info"
        header-icon="pencil"
        @header-icon-click="edit = !edit"
      >
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="id"
          :data="users"
          style="overflow: visible;"
        >
          <b-table-column label="Username" field="id" sortable v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column :visible="edit" label="Password" field="id" width="300px" sortable v-slot="props">
            <b-input type="password"
                v-model="props.row.password"
                placeholder="Edit to reset password">
            </b-input>
          </b-table-column>
          <b-table-column label="Roles" field="roles" width="400px" sortable v-slot="props">
            <template v-if="edit">
              <multiselect v-model="props.row.roles" placeholder="Search or add a roles" :options="roles" :multiple="true">
                <span slot="noResult">No matching roles found</span>
              </multiselect>
            </template>
            <template v-else>
              <b-taglist>
                <b-tag type="is-success" v-for="role in props.row.roles" :key="`user-${props.row.id}-roles-${role}`">
                    {{ role }}
                </b-tag>
              </b-taglist>
            </template>
          </b-table-column>
          <b-table-column :visible="edit" label="Actions" width="150px" field="actions" v-slot="props">
            <b-button icon-left="content-save" class="is-clickable mr-3" @click="updateUser(props.row, $event)" />
            <b-button icon-left="delete" class="is-clickable is-danger" @click="deleteUser(props.row.id, $event)" />
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No administrative users...</p>
            </div>
          </section>
        </b-table>
      </card-component>
    </section>
    <section class="section is-main-section">
      <card-component
        title="API Keys"
        class="has-table has-mobile-sort-spaced"
        icon="file-lock-outline"
        headerColor="info"
        header-icon="pencil"
        @header-icon-click="edit = !edit"
      >
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="id"
          :data="keys"
          style="overflow: visible;"
        >
          <b-table-column label="Identifier" field="id" sortable v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column label="Key" field="key" width="400px" sortable v-slot="props">
            <template v-if="edit">
              <b-field>
                  <b-input type="text"
                    v-model="props.row.key"
                    placeholder="API Key"
                    expanded
                    required
                    validation-message="Key must start with APIKEY- and contain only alpha-numeric values"
                    pattern="APIKEY-[a-zA-Z0-9]*"
                    icon-right="refresh"
                    icon-right-clickable
                    @icon-right-click="() => {props.row.key = generateApiKey()}"
                    >
                </b-input>
              </b-field>
            </template>
            <template v-else>
              {{ props.row.key }}
            </template>
          </b-table-column>
          <b-table-column label="Roles" field="roles" width="400px" sortable v-slot="props">
            <template v-if="edit">
              <multiselect v-model="props.row.roles" placeholder="Search or add a roles" :options="roles" :multiple="true">
                <span slot="noResult">No matching roles found</span>
              </multiselect>
            </template>
            <template v-else>
              <b-taglist>
                <b-tag type="is-success" v-for="role in props.row.roles" :key="`apikey-${props.row.id}-roles-${role}`">
                    {{ role }}
                </b-tag>
              </b-taglist>
            </template>
          </b-table-column>
          <b-table-column :visible="edit" label="Actions" width="150px" field="actions" v-slot="props">
            <b-button icon-left="content-save" class="is-clickable mr-3" @click="updateApiKey(props.row, $event)" />
            <b-button icon-left="delete" class="is-clickable is-danger" @click="deleteUser(props.row.id, $event)" />
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No API keys...</p>
            </div>
          </section>
        </b-table>
      </card-component>
      <tiles>
        <card-component title="New administrative user" icon="file-document-edit" headerColor="success" class="tile is-child">
          <form @submit.prevent="createUser">
            <b-field label="Username" horizontal>
              <b-input
                v-model="userForm.id"
                type="text"
                required
              />
            </b-field>
            <b-field label="Password" horizontal>
              <b-input
                v-model="userForm.password"
                type="password"
                autocomplete="new-password"
                required
              />
            </b-field>
            <b-field label="Roles" horizontal>
              <multiselect v-model="userForm.roles" placeholder="Search or add a roles" :options="roles" :multiple="true">
                <span slot="noResult">No matching roles found</span>
              </multiselect>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary" :disabled="userFormDisabled"
                    >Create user</b-button
                  >
                </div>
                <div class="control">
                  <b-button type="is-primary is-outlined" :disabled="userFormDisabled" @click="()=>{userForm.id = ''; userForm.password = ''; userForm.roles = []}"
                    >Reset</b-button
                  >
                </div>
              </b-field>
            </b-field>
          </form>
        </card-component>
        <card-component title="New API key" icon="file-document-edit" headerColor="success" class="tile is-child">
          <form @submit.prevent="createApiKey">
            <b-field label="Identifier" horizontal>
              <b-input
                v-model="apiKeyForm.id"
                type="text"
                required
              />
            </b-field>
            <b-field label="API Key" horizontal>
              <b-input
                v-model="apiKeyForm.key"
                type="text"
                required
                validation-message="Key must start with APIKEY- and contain only alpha-numeric values"
                pattern="APIKEY-[a-zA-Z0-9]*"
                icon-right="refresh"
                icon-right-clickable
                @icon-right-click="() => {apiKeyForm.key = generateApiKey()}"
              />
            </b-field>
            <b-field label="Roles" horizontal>
              <multiselect v-model="apiKeyForm.roles" placeholder="Search or add a roles" :options="roles" :multiple="true">
                <span slot="noResult">No matching roles found</span>
              </multiselect>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary" :disabled="apiKeyFormDisabled"
                    >Create API key</b-button
                  >
                </div>
                <div class="control">
                  <b-button type="is-primary is-outlined" :disabled="apiKeyFormDisabled" @click="()=>{apiKeyForm.id = ''; apiKeyForm.key = ''; apiKeyForm.roles = []}"
                    >Reset</b-button
                  >
                </div>
              </b-field>
            </b-field>
          </form>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import bcrypt from 'bcryptjs'

export default {
  name: 'AuthenticationManager',
  components: {
    HeroBar,
    CardComponent,
    TitleBar,
    Tiles,
    Multiselect
  },
  data () {
    return {
      isLoading: false,
      paginated: true,
      edit: false,
      users: [],
      keys: [],
      roles: [],
      userFormDisabled: false,
      apiKeyFormDisabled: false,
      userForm: {
        id: undefined,
        password: undefined,
        roles: []
      },
      apiKeyForm: {
        id: undefined,
        key: this.generateApiKey(),
        roles: []
      }
    }
  },
  computed: {
    titleStack () {
      return ['Authentication Manager']
    },
    ...mapState(['api'])
  },
  async created () {
    this.userInterval = setInterval(this.getUsers, 30000)
    this.apiKeyInterval = setInterval(this.getApiKeys, 30000)
    await Promise.all([
      this.getUsers(),
      this.getApiKeys(),
      this.getRoles()
    ])
  },
  beforeDestroy () {
    clearInterval(this.userInterval)
    clearInterval(this.apiKeyInterval)
  },
  methods: {
    async getRoles () {
      try {
        const roles = await this.api.get('/auth/roles')
        this.roles = roles.data
      } catch (e) {
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            this.getRoles()
          }
        })
        console.log(e)
      }
    },
    async getUsers () {
      try {
        const users = await this.api.get('/auth/users')
        this.users = users.data
      } catch (e) {
        clearInterval(this.userInterval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.userInterval)
            this.getUsers()
            this.userInterval = setInterval(this.getUsers, 30000)
          }
        })
        console.log(e)
      }
    },
    async getApiKeys () {
      try {
        const keys = await this.api.get('/auth/apikeys')
        this.keys = keys.data
      } catch (e) {
        clearInterval(this.apiKeyInterval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.apiKeyInterval)
            this.getApiKeys()
            this.apiKeyInterval = setInterval(this.getApiKeys, 30000)
          }
        })
        console.log(e)
      }
    },
    async createUser () {
      this.userFormDisabled = true
      try {
        const salt = bcrypt.genSaltSync(12)
        const user = {
          id: this.userForm.id,
          password: bcrypt.hashSync(this.userForm.password, salt),
          roles: this.userForm.roles
        }
        await this.api.post('/auth/users', user)
        await this.getUsers()
        this.userForm = {
          id: undefined,
          password: undefined,
          roles: []
        }
      } catch (e) {
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 20000
        })
      } finally {
        this.userFormDisabled = false
      }
    },
    async updateUser (user, event) {
      const target = event.currentTarget
      target.disabled = true
      try {
        await this.api.put(`/auth/users/${user.id}`, user)
        await this.getUsers()
      } catch (e) {
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 20000
        })
      } finally {
        target.disabled = false
      }
    },
    async deleteUser (userId, event) {
      const target = event.currentTarget
      target.disabled = true
      try {
        await this.api.delete(`/auth/users/${userId}`)
        await this.getUsers()
      } catch (e) {
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 20000
        })
      } finally {
        target.disabled = false
      }
    },
    async createApiKey () {
      this.apiKeyFormDisabled = true
      try {
        await this.api.post('/auth/apikeys', this.apiKeyForm)
        await this.getApiKeys()
      } catch (e) {
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 20000
        })
      } finally {
        this.apiKeyFormDisabled = false
      }
    },
    async updateApiKey (apiKey, event) {
      const target = event.currentTarget
      target.disabled = true
      try {
        await this.api.put(`/auth/apikeys/${apiKey.id}`, apiKey)
        await this.getApiKeys()
        this.apiKeyForm = {
          id: undefined,
          key: undefined,
          roles: []
        }
      } catch (e) {
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 20000
        })
      } finally {
        target.disabled = false
      }
    },
    async deleteApiKey (apiKeyId, event) {
      const target = event.currentTarget
      target.disabled = true
      try {
        await this.api.delete(`/auth/apikeys/${apiKeyId}`)
        await this.getApiKeys()
      } catch (e) {
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 20000
        })
      } finally {
        target.disabled = false
      }
    },
    generateApiKey () {
      return `APIKEY-${Math.random().toString(20).substr(2, 12)}${Math.random().toString(20).substr(2, 12)}`.toUpperCase()
    }
  }
}
</script>
